import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.css']
})
export class InstagramComponent implements OnInit {

  constructor(private analytics_service: AnalyticsService) { }

  ngOnInit() 
  {
    this.analytics_service.trackPage('root', '/hello_ig') 
  }

  sendEvent(social: string)
  {
    this.analytics_service.triggerEvent('view_social',{ event_category: social, event_label: social});
  }

}
