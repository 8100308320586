import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallerynft',
  templateUrl: './gallerynft.component.html',
  styleUrls: ['./gallerynft.component.css']
})
export class GallerynftComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
