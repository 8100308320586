import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // userData: Observable<firebase.User>;
  private af_auth: firebase.auth.Auth;

  constructor(private firebase: FirebaseService) {
    // this.userData = angularFireAuth.authState;
    this.af_auth = firebase.auth;
  }

  /* Sign up */
  /* Sign up */
 async SignUp(email: string, password: string): Promise<void> {
   await this.af_auth.createUserWithEmailAndPassword(email, password)
   //await this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password)
  // .then(res => {
  // console.log('You are Successfully signed up!', res);
  // })
  // .catch(error => {
  // console.log('Something is wrong:', error.message);
  // });
  // }
    
  /* Sign in */
  // SignIn(email: string, password: string) 
  // {
  //   this.angularFireAuth
  //   .auth
  //   .signInWithEmailAndPassword(email, password)
  //   .then(res => {
  //   console.log('Youre in!');
  //   })
  //   .catch(err => {
  //   console.log('Something went wrong:',err.message);
  //   });
  // }
  
  /* Sign out */
  // SignOut() 
  // {
  //   this.angularFireAuth
  //   .auth
  //   .signOut();
  // }
  

}


}

