import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import * as d3 from "d3";
import { Simulation } from 'd3';
declare const $: any;

export interface DataBubble 
{
    username: string;
    id: string;
    pct_int: number;
    display_url: string;
    likes?: number;
    comments?: number;
    interactions?: number;
}

@Component({
  selector: 'app-bubble',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.css']
})
export class BubbleComponent implements OnChanges {

	@Input() data: DataBubble[];

	private const_size: number;
	private pack: any;
	private simulation: any;
	private node: any;
	private nodes: any;
	private forceCollide: any;
	private focusedNode: any;
	private _svg: any;
	private selected_pattern: any;

	private get height(): number 
	{
		return window.innerHeight;
	}

	private get width(): number 
	{
		return document.body.clientWidth;
	}

	private get centerX(): number 
	{
		return this.width * 0.4	
	}

	private get centerY(): number 
	{
		if (this.width < 700)
		{
			return this.height * 0.4;
		}
		else
		{
			return this.height * 0.33;
		}
		
	}

	private get svg(): any 
	{
		if (!this._svg)
		{
			this._svg = d3.select('#bubble_svg').append("svg")//.style('width', this.width).style('height', this.height);
		}

		return this._svg;
	}

	constructor() 
	{ 
		console.log(`bubble component constructed.`);
	}

	ngOnInit()
	{
		console.log("bubble here!")
	}

	async ngOnChanges()
	{
		if (!this.data)
		{
			return;
		}

		console.debug(`cargando usuraios...`);
		console.debug('bubble chart component starting')
		await this.createBubbleChart();
		console.debug('bubble chart component done.')
			
	}

	async ngAfterViewChange(): Promise<void> 
	{
		console.log("bubble here!")
		await this.data
		if (!this.data)
		{
			return;
		}

		console.debug(`cargando usuraios...`);
		console.debug('bubble chart component starting')
		await this.createBubbleChart();
		console.debug('bubble chart component done.')
		
	}

	private async createBubbleChart(): Promise<void>
	{
		//size of bubble chart 
		this.const_size = 40;
		//console.log('bubble chart dimensions')
		//console.log(this.width);
		//console.log(this.height);
		//console.log(this.svg);


		if (this.width < 700)
		{
			this.const_size = 20
		}
		/*
		if (this.width < 700 && this.height < 350)
		{
			this.const_size = 400
		}*/


		
		this.optimizeForMobile();
		console.debug(`optimized for mobile`);
		this.pack = this.createPack();
		console.debug(`created pack`);
		const result = this.createSimulation();
		console.debug(`created simulation`);
		this.simulation = result.simulation;
		this.forceCollide = result.force_collide;
		this.nodes = this.createNodes();
		console.debug(`created nodes`);
		this.simulation.nodes(this.nodes).on('tick', () =>
		{
			node
			.attr('transform', d => `translate(${d.x},${d.y})`) /*d.x   d.y*/
			.select('circle')
			.attr('r', d => d.r );
		});

		console.debug(`added translation to nodes`);
		//this.svg.style('background-color', '#F08080');
		this.svg.style('background-color', 'transparent');
		const node = this.node = this.createInteractiveGraph();
		
		console.debug(`created interactive graph`);

		this.createLegendAndTitle();
		console.debug(`created legend and title`);
		this.createInfoBox();

		console.debug(`created info box`);
		// animate
		this.node.on('click', currentNode => this.animateBubbles(currentNode))

		// blur
		d3.select(document).on('click', () => this.blurImage());
		console.debug(`Created bubble chart.`);
	}

	private blurImage()
	{
		const focusedNode = this.focusedNode;
		const self = this;
		let target = d3.event.target;
		let currentTarget = d3.event.currentTarget;

		// check if click on document but not on the circle overlay
		if (!target.closest('#circle-overlay') && focusedNode) {
			focusedNode.fx = null;
			focusedNode.fy = null;
			
			this.simulation.alphaTarget(0.2).restart();
			d3.transition().duration(2000).ease(d3.easePolyOut)
				.tween('moveOut', function () {
					console.debug('tweenMoveOut', focusedNode);
					let ir = d3.interpolateNumber(focusedNode.r, focusedNode.radius);
					return function (t) {
						focusedNode.r = ir(t);
						self.simulation.force('collide', self.forceCollide);
					};
				})
				.on('end', () => {
					console.debug(self.focusedNode);
					$(`#${self.focusedNode.id}`).css('fill', self.selected_pattern);
					self.focusedNode = null;
					self.simulation.alphaTarget(0);
				})
				.on('interrupt', () => {
					self.simulation.alphaTarget(0);
				});
			// hide all circle-overlay
			d3.selectAll('.circle-overlay').classed('hidden', true);
			d3.selectAll('.node-icon').classed('node-icon--faded', false);
		}
	}
	
	private animateBubbles(currentNode: any)
	{
		d3.event.stopPropagation();
		console.debug('currentNode', currentNode);
		let currentTarget = d3.event.currentTarget; // the <g> el
		if (currentNode === this.focusedNode) {
			// no focusedNode or same focused node is clicked
			return;
		}
		let lastNode = this.focusedNode;
		this.focusedNode = currentNode;
		this.simulation.alphaTarget(0.2).restart();
		// hide all circle-overlay
		d3.selectAll('.circle-overlay').classed('hidden', true);
		d3.selectAll('.node-icon').classed('node-icon--faded', false);
		d3.selectAll('.node-icon').style('border-radius', '50%');
		// don't fix last node to center anymore
		if (lastNode) {
			lastNode.fx = null;
			lastNode.fy = null;
			this.node.filter((d, i) => i === lastNode.index)
				.transition().duration(2000).ease(d3.easePolyOut)
				.tween('circleOut', () => {
					let irl = d3.interpolateNumber(lastNode.r, lastNode.radius);
					return (t) => {
						lastNode.r = irl(t);
					}
				})
				.on('interrupt', () => {
					lastNode.r = lastNode.radius;
				});
		}
		 
		// if (!d3.event.active) simulation.alphaTarget(0.5).restart();
		d3.transition().duration(2000).ease(d3.easePolyOut)
			.tween('moveIn', () => {
				// console.log('tweenMoveIn', currentNode);
				let ix = d3.interpolateNumber(currentNode.x, this.centerX);
				let iy = d3.interpolateNumber(currentNode.y, this.centerY);
				let ir = d3.interpolateNumber(currentNode.r, this.centerY * 0.5);
				return t => {
					 // console.log('i', ix(t), iy(t), ir(t));
					currentNode.x = ix(t);
					currentNode.y = iy(t);
					currentNode.r = ir(t);
					this.simulation.force('collide', this.forceCollide);
					

				};
			})
			.on('start', () => {
				this.simulation.alphaTarget(0);
				let $currentGroup = d3.select(currentTarget);
				//this.selected_pattern = $currentGroup.select('circle').style("fill")
				//$currentGroup.select('circle').style("fill", "#333EFF")
			})
			.on('end', () => {
				this.simulation.alphaTarget(0);
				let $currentGroup = d3.select(currentTarget);
				$currentGroup.select('.circle-overlay')
					.classed('hidden', false);
				$currentGroup.select('.node-icon')
					.classed('node-icon--faded', true);
			})
			.on('interrupt', () => {
				console.debug('move interrupt', currentNode);
				currentNode.x = null;
				currentNode.y = null;
				this.simulation.alphaTarget(0);
			});
	}

	private createLegendAndTitle()
	{
		this.node.append('clipPath')
		.attr('id', d => `clip-${d.id}`)
		.append('use')
		.attr('xlink:href', d => `#${d.id}`);

		const format = d3.format(',d');

		this.node.append('title')
				.text(d => (d.cat + '::' + d.name + '\n' + format(d.value)));

		// display text as circle icon
		this.node.filter(d => !String(d.icon))
		.append('text')
		.classed('node-icon', true)
		.attr('clip-path', d => `url(${d.id})`)
		.selectAll('tspan')
		.data(function(d:any) 
		{
			return d.icon
		}) //d => d.icon.split(';')
		.enter()
		.append('tspan')
		.attr('x', 0)
		.attr('y', (d, i, nodes) => 
		{
			return (13 + (i - nodes.length / 2 - 0.5) * 10)
		})
		.text(name);

		//let legendOrdinal = d3.legendColor()
		//	.scale(scaleColor)
		//		.shape('circle');
		let legend = this.svg.append('g')
				.classed('legend-color', true)
				.attr('text-anchor', 'start')
				.attr('transform','translate(20,30)')
				.style('font-size','12px')
				//.call(legendOrdinal);

		let sizeScale = d3.scaleOrdinal()
		.domain(['less use', 'more use'])
			.range([5, 10] );
	}

	/** Creates the information box that opens when we click on a bubble */
	private createInfoBox()
	{
		const self = this;
		let infoBox = this.node.append('foreignObject')
			.classed('circle-overlay hidden', true)
			.attr('x', -100)
			.attr('y', -100)
			.attr('height', 200  ) /*** 350 * 0.8 */
			.attr('width', 200  )
			.append('xhtml:div')
			.classed('circle-overlay__inner', true);

		infoBox.append('h2')
			.classed('circle-overlay__title', true)
			.text(d => d.name);
		infoBox.append('p')
			.classed('circle-overlay__body', true)
			.html(d => d.desc);
		
		/*
		infoBox.append('p')
			.classed('circle-overlay__body', true)
			.html(d => 'Ver detalles')
			.style('cursor', 'pointer')
			.on("click", function(d) {
				self.router.navigate(['interactions'], 
				{ 
					queryParams: 
					{ 
						report_id: self.account_info.user_details.id, 
						ig_uid: d.id,
						show: 'all'
					} 
				});
	   		});
			//.on("click", );
		*/
	}

	private createInteractiveGraph()
	{
		const self = this;
		let defs = this.svg.append('svg:defs');
		this.nodes.forEach(function(d, i) 
		{
			defs.append("svg:pattern") // patterns
			.attr("id", "grump_avatar" + i)
			.attr("width",  d.radius * 2 ) /*2 * d.radius */
			.attr("height",  d.radius * 2 ) /*2 * d.radius */
			.attr("x", d.radius) //grosor del eje x , eje y
			.attr("y", d.radius)
			.attr("patternUnits", "userSpaceOnUse")
			.append("svg:image") // tamano de la imagen
			.attr("xlink:href", d.icon)
			//.attr("transform", "translate(" + d.radius /2 + "," + d.radius / 2 + ")")
			.attr("width",  d.radius * 2) /*2 * d.radius */
			.attr("height", d.radius * 2) /*2 * d.radius */
			.attr("preserveAspectRatio","xMinYMin slice")
			.attr("x",0) //grosor del eje x , eje y
			.attr("y",0);
		})
		
		let node = this.svg
		.selectAll('.node')
		.data(this.nodes)
		.enter().append('g')
		.attr('class', 'node')
		.call(d3.drag()
		.on('start', (d: any) => {
			if (!d3.event.active) this.simulation.alphaTarget(0.2).restart();
			d.fx = d.x;
			d.fy = d.y;
		})
		.on('drag', (d: any) => {
			d.fx = d3.event.x;
			d.fy = d3.event.y;
		})
		.on('end', (d: any) => {
			if (!d3.event.active) this.simulation.alphaTarget(0);
			d.fx = null;
			d.fy = null;
		}));
		
		// Size adjustments and transition effects
		node
		.append('circle')
		.attr('id', d => d.id)
		.attr("cx", 0)
		.attr("cy", 0)
		.style("fill", "#333EFF")
		.style("fill", (d:any, i) =>  "url(#grump_avatar" + i + ")")
		.transition()
		.duration(2000)
		.ease(d3.easeElasticOut)
		.tween('circleIn', (d) => {
			let i = d3.interpolateNumber(0, d.radius);
			return t => {
				d.r = i(t);
				this.simulation.force('collide', this.forceCollide);
			}
		})

		return node;
	}

	private optimizeForMobile()
	{
		// reduce number of circles on mobile screen due to slow computation
		if ('matchMedia' in window && window.matchMedia('(max-device-width: 767px)').matches) 
		{
			//this.data = this.data.slice(0,15)
		}
	}

	private createNodes()
	{
		//this.optimizeForMobile();
		console.debug(`creating nodes... pack = ${this.pack}`);
		let root = d3.hierarchy({ name: "root", children: this.data })
		.sum(function(d: any) { return d.interactions});

		console.debug(`created hierarchy`);
	
		const values = this.data.map(data => data.interactions)
		const max = Math.max(...values);
		var Size = d3.scaleSqrt().domain([0,max]).range([0,this.const_size]) //.exponent(5).

		console.debug(`creating scale domain`);

		// we use pack() to automatically calculate radius conveniently only
		// and get only the leaves
		console.debug(`mapping node. pack = ${this.pack}, centerX = ${this.centerX}, centerY = ${this.centerY}`);
		let nodes = this.pack(root).leaves().map(node => 
		{
			//console.log('node:', node.x, (node.x - this.centerX) * 2);
			//console.log('centerX:',this.centerX);
			const data = node.data as DataBubble;
			console.debug(`current data = ${JSON.stringify(data, null, 4)}`);

			const ans = {
				x: this.centerX + (node.x - this.centerX) * 3, // magnify start position to have transition to center movement
				y: this.centerY + (node.y - this.centerY) * 3,
				r: 0, // for tweening
				radius: Size(data.interactions),
				//data.interactions,//data.pct_int * this.const_size,//node.r, //original radius normalize
				id: data.id,//'1' + '.' + (data.username),//.replace(/\s/g, '-')
				cat: '1',
				name: data.username,
				value: data.interactions,
				icon: data.display_url,
				desc: "<br>  interacciones: " + data.interactions ,//data.username +
			};

			console.debug(`current node = ${JSON.stringify(ans, null, 4)}`);
			return ans;
		});

		return nodes;
	}

	private createSimulation(): { force_collide: any, simulation: Simulation<any, undefined> }
	{
		let strength = 0.05;/* 0.05*/
		let force_collide = d3.forceCollide(function(d: any) {return d.r + 1 }); /*return */

		// use the force
		let simulation = d3.forceSimulation()
				.force('charge', d3.forceManyBody())
				.force('collide', force_collide)
				.force('x', d3.forceX(this.centerX ).strength(strength))
				.force('y', d3.forceY(this.centerY ).strength(strength));

		const ans = 
		{
			force_collide: force_collide,
			simulation: simulation
		};

		return ans;
	}
	
	private createPack()
	{
		// use pack to calculate radius of the circle
		let pack = d3.pack()
		.size([this.width , this.height])
		.padding(1.5);
		 
		return pack;
	}
}