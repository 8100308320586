import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { DictionaryService } from '../services/dictionary.service';
import { AnalyticsService } from '../services/analytics.service';
declare var cartodb: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})


export class MapComponent implements OnInit {

  

  constructor(private dictionary_service: DictionaryService,
              private analytics_service: AnalyticsService)
               { }

  async ngOnInit() 
  {
 
    this.analytics_service.trackPage('Map', '/map')
    //await this.waitForPageToLoad();
    // var cartodb = require('@carto/carto.js');
    cartodb.createVis('map', 'http://documentation.carto.com/api/v2/viz/2b13c956-e7c1-11e2-806b-5404a6a683d5/viz.json');

    // window.onload = function() {
    //   cartodb.createVis('map', 'http://documentation.carto.com/api/v2/viz/2b13c956-e7c1-11e2-806b-5404a6a683d5/viz.json');
    // }
    // const iFrameDOM = $("iframe#mapa").contents();
    // iFrameDOM.find(".CDB-Embed-tab").css("padding", "0");
    
    /*var cssLink = document.createElement("link");
    cssLink.href = "map.iframe.css"; 
    cssLink.rel = "stylesheet"; 
    
    cssLink.type = "text/css"; 
    frames['mapa'].document.head.appendChild(cssLink);*/

    /*var frm = frames['mapa'].document;
    var otherhead = frm.getElementsByTagName("head")[0];
    var link = frm.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", "map.iframe.css");
    otherhead.appendChild(link);*/

    // this.cartodb.createVis('map', 'https://documentation.cartodb.com/api/v2/viz/2b13c956-e7c1-11e2-806b-5404a6a683d5/viz.json', {
    //         shareable: true,
    //         title: true,
    //         description: true,
    //         search: true,
    //         tiles_loader: true,
    //         center_lat: 0,
    //         center_lon: 0,
    //         zoom: 2
    //     })
    //     .done(function(vis, layers) {
    //       // layer 0 is the base layer, layer 1 is cartodb layer
    //       // setInteraction is disabled by default
    //       layers[1].setInteraction(true);
    //       layers[1].on('featureOver', function(e, latlng, pos, data) {
    //         this.cartodb.log.log(e, latlng, pos, data);
    //       });

    //       // you can get the native map to work with it
    //       var map = vis.getNativeMap();

    //       // now, perform any operations you need
    //       // map.setZoom(3);
    //       // map.panTo([50.5, 30.5]);
    //     })
    //     .error(function(err) {
    //       console.log(err);
    //     });


  //       var carto = require('@carto/carto.js');
  //   const map = L.map('map').setView([30, 0], 3);
  //     map.scrollWheelZoom.disable();

  //     L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png', {
  //       maxZoom: 18
  //     }).addTo(map);
      
  //   var client = new carto.Client({
  //     apiKey: '62d61e5dd6c209234341cc918fea049573b4e0e1',
  //     username: 'yeess'
  // });

  // const source = new carto.source.Dataset('ne_10m_populated_places_simple');
  //     const style = new carto.style.CartoCSS(`
  //       #layer {
  //         marker-width: 7;
  //         marker-fill: #EE4D5A;
  //         marker-line-color: #FFFFFF;
  //       }
  //     `);
  //     const layer = new carto.layer.Layer(source, style);

  //     client.addLayer(layer);
  //     client.getLeafletLayer().addTo(map);
  // }
    
  }

      //window.onload = main;


  // launchMap()
  // {
  //   const map = new MapboxMap({
  //     container: this.mapboxContainer.nativeElement,
  //     style: BASEMAP.POSITRON,
  //     interactive: false,
  //     center: [initialViewState.longitude, initialViewState.latitude],
  //     zoom: initialViewState.zoom
  //   });
  // }
  

  

  
  ngAfterViewInit() {
    //this.launchMap(INITIAL_VIEW_STATE);
  }

  get title(): string
  {
    switch (this.dictionary_service.user_language )
    {
      case "en":
         return "Gentrification Alert Project Map"
      case "es":
        return  "Mapa de Proyecto de Alerta de Gentrificación"
    }
  }
  private waitForPageToLoad(): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      $(document).ready(() => resolve())
    })
  }
}
