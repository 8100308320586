import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from '../../about/about.component';
import { ContactComponent } from '../../contact/contact.component';
import { MapComponent } from '../../map/map.component';
import { HomeComponent } from '../../home/home.component';
import { ErrorComponent } from '../../error/error.component';
import { AppComponent } from 'src/app/app.component';
import { InstagramComponent } from 'src/app/instagram/instagram.component';
import { MainComponent } from 'src/app/main/main.component';
import { TrainingComponent } from 'src/app/training/training.component';
import { TerminalComponent } from 'src/app/terminal/terminal.component';
import { MediaComponent } from 'src/app/media/media.component';

const routes: Routes =
[
  { path: '' , component: MainComponent },
  { path: 'ig',  component: InstagramComponent },
  { path: 'me',  component: InstagramComponent },
  { path: 'training',  component: TrainingComponent },
  { path: 'media',  component: MediaComponent },
  { path: '**' , redirectTo: ''}
  // { path: 'about',  component: AboutComponent },
  // { path: 'map', component: MapComponent },
  // { path: 'contact', component: ContactComponent },
  // { path: '**', component: ErrorComponent }

];
@NgModule({
  exports: [RouterModule],
  imports: [
    //RouterModule.forChild(routes)
    RouterModule.forRoot(routes)
  ],
  declarations: []
})
export class RoutingModule { }