import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { MapComponent } from './map/map.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ErrorComponent } from './error/error.component';
import { RoutingModule } from './modules/routing/routing.module';
import { SidebarModule } from 'ng-sidebar';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { ParticlesModule } from 'angular-particle';
import { HttpClientModule } from '@angular/common/http';
import { GalleryComponent } from './gallery/gallery.component';
import { ExperienceComponent } from './experience/experience.component';
import { EducationComponent } from './education/education.component';
import { BookComponent } from './book/book.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { MovieComponent } from './movie/movie.component';
import { BannerComponent } from './banner/banner.component';
import { ProjectsComponent } from './projects/projects.component';
import { EntrepreneurComponent } from './entrepreneur/entrepreneur.component';
import { CountriesComponent } from './countries/countries.component';
import { BlogComponent } from './blog/blog.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { TechStackComponent } from './tech-stack/tech-stack.component';
import { BubbleComponent } from "./bubble/bubble.component";
import { StockMarketComponent } from './stock-market/stock-market.component';
import { InstagramComponent } from './instagram/instagram.component';
import { MainComponent } from './main/main.component';
import { FeaturesComponent } from './features/features.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { TrainingComponent } from './training/training.component';
import { environment } from "./../environments/environment.prod";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { TerminalComponent } from './terminal/terminal.component';
import { MediaComponent } from './media/media.component';
import { GallerynftComponent } from './gallerynft/gallerynft.component';
import { YoutubeComponent } from './youtube/youtube.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationBarComponent,
    MapComponent,
    AboutComponent,
    ContactComponent,
    ErrorComponent,
    GalleryComponent,
    ExperienceComponent,
    EducationComponent,
    BookComponent,
    TestimonialsComponent,
    MovieComponent,
    BannerComponent,
    ProjectsComponent,
    EntrepreneurComponent,
    CountriesComponent,
    BlogComponent,
    NewsletterComponent,
    TechStackComponent,
    BubbleComponent,
    StockMarketComponent,
    InstagramComponent,
    MainComponent,
    FeaturesComponent,
    SocialMediaComponent,
    TrainingComponent,
    TerminalComponent,
    MediaComponent,
    GallerynftComponent,
    YoutubeComponent
  ],
  imports: [
    BrowserModule,
    SidebarModule.forRoot(),
    FormsModule,
    HttpClientModule,
    RoutingModule,
    AngularFontAwesomeModule,
    // ParticlesModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
