import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit()
  {
    $(document).ready(function(){
      (<any>$("#testimonial-slider")).owlCarousel({
          items:1,
          itemsDesktop:[1000,1],
          itemsDesktopSmall:[979,1],
          itemsTablet:[768,1],
          pagination:false,
          navigation:false,
          navigationText:["",""],
          slideSpeed:1000,
          autoPlay:true
      });
  });
  }

}
