import { Component, OnInit } from '@angular/core';
declare let gapi:any;

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})
export class CountriesComponent implements OnInit {

  constructor() { }

  ngOnInit() 
  {
    gapi.analytics.ready(function() {

      /**
       * Authorize the user immediately if the user has already granted access.
       * If no access has been created, render an authorize button inside the
       * element with the ID "embed-api-auth-container".
       */
      gapi.analytics.auth.authorize({
        container: 'embed-api-auth-container',
        clientid: '297265366684-0s19gvumu9ufkqro814489hhlnk6s69r.apps.googleusercontent.com'
      });
    
    
      /**
       * Create a ViewSelector for the first view to be rendered inside of an
       * element with the id "view-selector-1-container".
       */
      var viewSelector1 = new gapi.analytics.ViewSelector({
        container: 'view-selector-1-container'
      });
    
    
      // Render both view selectors to the page.
      viewSelector1.execute();
    
    
      /**
       * Create the first DataChart for top countries over the past 30 days.
       * It will be rendered inside an element with the id "chart-1-container".
       */
      var dataChart1 = new gapi.analytics.googleCharts.DataChart({
        query: {
          metrics: 'ga:sessions',
          dimensions: 'ga:country',
          'start-date': '2019-08-01',
          'end-date': 'yesterday',
          'max-results': 100,
          sort: '-ga:sessions'
        },
        chart: {
          type: 'GEO',
          container: 'chart-1-container',
          options: {
            width: '100%',
            colorAxis: {colors: ['#5ec5ff', '#0996f2', '#0975da']},
            // backgroundColor: '#59408b',
            // datalessRegionColor: '#eeeab5',
            // defaultColor: '#eeeab5',
            //region: '155', // Western Europe
            //displayMode: 'markers'
          }
        }
      }
      );
    
      /**
       * Update the first dataChart when the first view selecter is changed.
       */
      viewSelector1.on('change', function(ids) {
        dataChart1.set({query: {ids: ids}}).execute();
      });
  
    
    });
  }

}
