import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
declare let $:any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  input_email: string = '';
  input_message: string = '';
  input_name: string = '';
  constructor(private http_client: HttpClient) { }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    /* Contact Form */
    $("#contactForm").validator().on("submit", function(event) 
    {
      if (event.isDefaultPrevented()) 
      {
          // handle the invalid form...
          cformError();
          csubmitMSG(false, "Please fill all fields!");
      } else 
      {
          // everything looks good!
          event.preventDefault();
      }
    });

    function csubmitMSG(valid, msg) 
    {
      if (valid) 
      {
          var msgClasses = "h3 text-center tada animated";
      } else {
          var msgClasses = "h3 text-center";
      }
      $("#cmsgSubmit").removeClass().addClass(msgClasses).text(msg);
    }

    function cformError() 
    {
        $("#contactForm")
        .removeClass()
        .addClass('shake animated')
        .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() 
        {
            $(this).removeClass();
        });
    }
  }


  async sendMessage()
  {
    // alert('Failed. Server under maintance. Try another way.')
    //this.analytics_service.triggerEvent('contact_email', { email: this.input_email, name: this.input_name });
    try 
    {
      //this.view_service.loading_message = this.view_service.getLocalText('sending_message');
      //this.view_service.loading = true;
      let url = `https://us-central1-epsilon-delta-237721.cloudfunctions.net/contact`;
      let path = `${url}?from=${this.input_email}&text=${this.input_message}&subject=nuevo mensaje de ${this.input_name} (${this.input_email})`;
  
      await this.http_client.post(path, null).toPromise();
      //await this.http_client.post(url, JSON.stringify(body), { headers: {'content-type': 'text/plain'}}).toPromise();
      //this.view_service.loading = false;
      //await this.view_service.showPopup(this.view_service.getLocalText('send_message_popup'));
      alert('Message sent successfully. I will contact you as soon as possible.')
      this.input_email = this.input_message = this.input_name = '';
    }
    catch (err)
    {
      //this.view_service.loading = false;
      alert('Email invalid or missing subject or text. Try again.')
      //await this.view_service.showPopup(this.view_service.getLocalText('send_message_popup_failed'));
    }
  }

}
