import { Injectable } from '@angular/core';
/** The google analytics gtag function that will be imported from the index.html */
declare let gtag: (...params: any[]) => void;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  /** The id for the google analyics account */
  private readonly ga_uid: string = 'UA-145095941-1';

  constructor() { }

  trackPage(title: string, path: string)
  {
    this.trackData({
      'page_title': title,
      'page_path': path
    });
  }

  triggerEvent(event_name: string, event_data?: {[key: string]: string})
  {
    try
    {
      if (event_data)
      {
        gtag('event', event_name, event_data);
      }
      else 
      {
        gtag('event', event_name);
      }
  
      console.log(`sent event ${event_name} to gtag`);
    }
    catch (e)
    {
      console.warn(`failed to sent event ${event_name}: ${e.message}`)
    }
    
  }


  /** send data to the google analytics engine */
  private trackData(data: {[key: string]: string})
  {
    gtag('config', this.ga_uid, data);
  }

}
