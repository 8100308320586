import { Component, OnInit } from '@angular/core';
import { DataBubble } from '../bubble/bubble.component';

@Component({
  selector: 'app-tech-stack',
  templateUrl: './tech-stack.component.html',
  styleUrls: ['./tech-stack.component.css']
})
export class TechStackComponent implements OnInit {

  data_bubble: DataBubble[];
  constructor() { }

  async ngOnInit() 
  {
    this.data_bubble = await [
      {  id: "1", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/github.png"},
      {  id: "2", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/vscode.png"},
      {  id: "3", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/python.png"},
      {  id: "4", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/rstudio.png"},
      {  id: "5", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/sas.jpeg"},
      {  id: "6", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/spark.png"},
      {  id: "7", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/scilab.png"},
      {  id: "8", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/mongodb.png"},
      {  id: "9", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/firebase.png"},
      {  id: "10", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/mysql.png"},
      {  id: "11", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/s3.png"},
      {  id: "12", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/neo4j.png"},
      {  id: "13", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/matplotlib.png"},
      {  id: "14", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/d3.png"},
      {  id: "15", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/windows.png"},
      {  id: "16", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/linux.png"},
      {  id: "17", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/macos.png"},
      {  id: "18", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/angular.png"},
      {  id: "19", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/js.png"},
      {  id: "20", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/css.png"},
      {  id: "21", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/html.png"},
      {  id: "22", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/jquery.png"},
      {  id: "23", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/nodejs.png"},
      {  id: "24", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/azure.png"},

      {  id: "25", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/aws.png"},
      {  id: "26", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/google.png"},
      {  id: "27", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/latex.png"},
      {  id: "28", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/analytics.png"},
      {  id: "29", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/tensorflow.png"},
      {  id: "30", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/opencv.png"},
      {  id: "31", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/airflow.png"},
      {  id: "32", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/docker.png"},


      {  id: "33", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/kubernetes.png"},
      {  id: "34", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/jenkins.png"},
      {  id: "35", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/postman.png"},
      {  id: "36", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/bitbucket.png"},
      {  id: "37", interactions: 101, username: "bla", pct_int: .10, display_url: "./../../assets/img/jira.png"}
    ];
    console.log(this.data_bubble)
  }

}
