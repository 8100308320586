import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private analytics_service: AnalyticsService) { }

  ngOnInit() 
  {
    this.analytics_service.trackPage('About','/about');
  }

  sendEvent(social: string)
  {
    this.analytics_service.triggerEvent('view_social',{ event_category: 'visit_social', event_label: social});
  }

}
