import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  get auth(): firebase.auth.Auth {
    return firebase.auth();
  }

  get firestore(): firebase.firestore.Firestore {
    return firebase.firestore();
  }

  constructor() {
    firebase.initializeApp(environment.firebaseConfig);
  }
}
