import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      (<any>$("#carouselExample")).owlCarousel({
          items:3,
          itemsDesktop:[1000,10],
          itemsDesktopSmall:[979,5],
          itemsTablet:[768,5],
          itemsMobile : [480,4],
          pagination:false,
          navigation:false,
          navigationText:["",""],
          slideSpeed:10,
          autoPlay:true
      });
    });
  }

}
