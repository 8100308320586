import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {



  constructor(private analytics_service: AnalyticsService) 
  { 

  }

  ngOnInit() 
  {
    this.analytics_service.trackPage('root', '/hello_ig') 
  }

  sendEvent(social: string)
  {
    this.analytics_service.triggerEvent('view_social',{ event_category: social, event_label: social});
  }

  get today(): any
  {
    return new Date();
  }
  

}
