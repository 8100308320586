import { Component, OnInit } from '@angular/core';
declare let $:any;

@Component({
  selector: 'app-entrepreneur',
  templateUrl: './entrepreneur.component.html',
  styleUrls: ['./entrepreneur.component.css']
})
export class EntrepreneurComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    /* Video Lightbox - Magnific Popup */
    $('.popup-youtube, .popup-vimeo').magnificPopup({
      disableOn: 0,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      iframe: {
          patterns: {
              youtube: {
                  index: 'youtube.com/', 
                  id: function(url) { 
                      /*       
                      var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
                      if ( !m || !m[1] ) return null;
                      return m[1];
                      */
                     return 'Ldg9Gk7ek3I';
                  },
                  src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0&mute=1&cc_load_policy=1'
              },
              vimeo: {
                  index: 'vimeo.com/', 
                  id: function(url) {        
                      var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
                      if ( !m || !m[5] ) return null;
                      return m[5];
                  },
                  src: 'https://player.vimeo.com/video/%id%?autoplay=1'
              }
          }
      }
  });
  }

}
