import { Component, OnInit } from '@angular/core';
import { DictionaryService } from '../services/dictionary.service';
import { AnalyticsService } from '../services/analytics.service';
import { particlejs_config } from './particlesjs-config';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  styleParticles: object = {};
  paramsParticles: object = {};

  constructor(private analytics_service: AnalyticsService) { }
 
  ngOnInit() 
  {
    this.analytics_service.trackPage('root', '/home') 
    this.styleParticles = 
    {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'z-index': 0,
      'top': '0px',
      'left': '0px',
      'right': '0px',
      'bottom': '0px',
    };
    this.paramsParticles = particlejs_config;
  }
}
