import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-stock-market',
  templateUrl: './stock-market.component.html',
  styleUrls: ['./stock-market.component.css']
})
export class StockMarketComponent implements OnInit {

  constructor() { }

  ngOnInit() 
  {
    $(document).ready(function(){
      (<any>$("#carouselExample")).owlCarousel({
          items:10,
          itemsDesktop:[1000,10],
          itemsDesktopSmall:[979,5],
          itemsTablet:[768,5],
          itemsMobile : [480,4],
          pagination:false,
          navigation:false,
          navigationText:["",""],
          slideSpeed:10,
          autoPlay:true
      });
    });
  }

}
