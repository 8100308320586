import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  email: string = '';
  password: string  = '654321';
  
  constructor(private authenticationService:AuthenticationService) { 

  }

  async ngOnInit() {
  }

  async signUp() {
    const res = await this.authenticationService.SignUp(this.email, this.password);
    this.email = '';
    this.password = '';
    }

}
