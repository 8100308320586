import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() 
  {
    let gallery = document.querySelector('#gallery');

    let getVal = function (elem, style) 
    { 
      return parseInt(window.getComputedStyle(elem).getPropertyValue(style)); 
    };

    let getHeight = function (item) 
    { 
      return item.querySelector('.content').getBoundingClientRect().height; 
    };

    let resizeAll = function () 
    {
        var altura = getVal(gallery, 'grid-auto-rows');
        var gap = getVal(gallery, 'grid-row-gap');
        Array.from(gallery.querySelectorAll('.gallery-item')).forEach(function (item) 
        {
            var el = item;
            var gitem = item.parentElement.parentElement;
            gitem.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
        });
    };

    Array.from(gallery.querySelectorAll('img')).forEach(function (item) 
    {
        item.classList.add('byebye');
        if (item.complete) {
            console.log(item.src);
        }
        else {
            item.addEventListener('load', function () {
                var altura = getVal(gallery, 'grid-auto-rows');
                var gap = getVal(gallery, 'grid-row-gap');
                var gitem = item.parentElement.parentElement;
                gitem.style.gridRowEnd = "span " + Math.ceil((getHeight(gitem) + gap) / (altura + gap));
                item.classList.remove('byebye');
            });
        }
    });
    
    window.addEventListener('resize', resizeAll);
    Array.from(gallery.querySelectorAll('.gallery-item')).forEach(function (item) {
        item.addEventListener('click', function () {        
            item.classList.toggle('full');        
        });
    });
  }

}
