import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DictionaryService } from '../services/dictionary.service';
import { SidebarModule } from 'ng-sidebar';

interface MenuText  {
  home :string ;
  about :string;
  map :string
}

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit 
{
 

  constructor(private router: Router, public dictionary_service: DictionaryService) 
  { 
    //
  }

 

  async ngOnInit() 
  {
    /* Navbar Scripts */
    // jQuery to collapse the navbar on scroll
    $(window).on('scroll load', function() {
      if ($(".navbar").offset().top > 20) {
          $(".fixed-top").addClass("top-nav-collapse");
      } else {
          $(".fixed-top").removeClass("top-nav-collapse");
      }
    });

    // closes the responsive menu on menu item click
    $(".navbar-nav li a").on("click", function(event) {
      if (!$(this).parent().hasClass('dropdown'))
          $(".navbar-collapse").collapse('hide');
      });

      /* Navbar Scripts */
    // jQuery to collapse the navbar on scroll
    $(window).on('scroll load', function() {
      if ($(".navbar").offset().top > 20) {
          $(".fixed-top").addClass("top-nav-collapse");
      } else {
          $(".fixed-top").removeClass("top-nav-collapse");
      }
  });

  }

  ngAfterViewChecked() 
  {

  }

  navToggle()
  {
    //
  }
}
