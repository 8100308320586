export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAakctXHuW0vFq1TRBMbpLRAcpPw0wHqP8",
    authDomain: "epsilon-delta-237721.firebaseapp.com",
    databaseURL: "https://epsilon-delta-237721.firebaseio.com",
    projectId: "epsilon-delta-237721",
    storageBucket: "epsilon-delta-237721.appspot.com",
    messagingSenderId: "297265366684",
    appId: "1:297265366684:web:4c98d8f50228c0e7384f59",
    measurementId: "G-2X335NS2SZ"
    }
};

