import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  email: string = '';
  private has_started_typing: boolean = false;

  constructor() { }

  ngOnInit() 
  {
    $("#email_input").on('keyup',e => {
      if (e.keyCode === 13) {
          // this.redirect();
      }
  });
  }

  onInputEmail()
  {
    if (!this.has_started_typing)
    {
      // this.analytics_service.triggerEvent('typing_before_discount');
      //this.analytics_service.triggerEvent('typing_email');
      this.has_started_typing = true;
    }
  }


  private async registerEmail()
  {
    let res;
    try 
    {
      //res = await this.mailchimp_service.createUser(this.email.toLowerCase(), report_data);
    }
    catch (err)
    {
      if (err.error && err.error.data && err.error.data.reason && err.error.data.reason.search('already registered') > 0)
      {
        res = err.error.data;
      }
      else 
      {
        // this.analytics_service.triggerEvent(`failed-register-email`, {'error': JSON.stringify(err) });
        // this.view_service.showPopup(this.view_service.getLocalText('error_registrar'));
        return;
      }
    }

    // this.analytics_service.triggerEvent(`register-email`);
    return res;
  }

}
